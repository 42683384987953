/**
 *   was created by tigran at 26.05.23
 **/

import React, {FC, ReactNode} from "react"
import cls from "../header.module.scss"
import Link from "next/link";

interface IProps {
    item: any
    children: ReactNode
    realPath?: string
}

const RenderLinkComponent: FC<IProps> = ({item: {slug, new_tab, url_key}, children, realPath}) => {
    return (
        <Link
            href={(url_key || slug && `/${realPath}page/${slug}`) || ""}
            prefetch={false}
        >
            {
                new_tab
                    ? <a className={cls.menu__li__a} target="_blank">{children}</a>
                    : <a className={cls.menu__li__a}>{children}</a>
            }
        </Link>

    )
}

export default RenderLinkComponent