/**
 *   was created by tigran at 12.06.23
 **/

import React, {FC} from "react"
import cls from "../header.module.scss"
import {ArrowRoundedRight6x9Svg} from "../../../svg";
import RenderLinkComponent from "./RenderLinkComponent";
import NavMenu from "./NavMenu";

interface IProps {
    items: any
    realPath: any
}

const ItemList: FC<IProps> = ({items, realPath}) => {
    return (
        <>
            {
                items.map((item: any, index: any) => {
                    let arrow;
                    let submenu;

                    if (item.children && item.children.length) {
                        arrow = <ArrowRoundedRight6x9Svg className={cls.menu__arrow}/>;
                        submenu = (
                            <div className={cls.menu__submenu}>
                                <NavMenu items={item.children}/>
                            </div>
                        );
                    }

                    return (
                        <li
                            className={cls.menu__li}
                            key={index}
                        >
                            <RenderLinkComponent item={item} realPath={realPath}>
                                <span className={cls.submenu__item_name}>{item.name}</span>
                                {arrow}
                            </RenderLinkComponent>
                            {submenu}
                        </li>
                    )
                })
            }
        </>
    )
}

export default ItemList