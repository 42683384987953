import React from "react";
import classNames from "classnames";
import cls from "../header.module.scss"

import ItemList from "./ItemList";

function NavMenu(props: any) {
    const {layout = "classic", withIcons = false, items = [], onClick, realPath = ""} = props;

    const classes = classNames(`${cls.menu} ${cls[`menu--layout--${layout}`]}`,
        withIcons && cls["menu--with-icons"]
    );

    return (
        <ul className={classes}>
            <ItemList items={items} realPath={realPath}/>
        </ul>
    )
}


export default NavMenu;
